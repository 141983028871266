import Vue from 'vue';
import VueRouter from 'vue-router';
import expo from '@/router/expo';
Vue.use(VueRouter);

let NotFound = () => import(/* webpackChunkName: "dome" */ '@/views/NotFound');

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) { // 解决重复报错的问题
  return (originalPush.call(this, location)).catch(err => err);
};
const routes = [
  // { path: '/:lang', name: 'userlogin', component: userlogin, meta: { isLogin: false } },
  ...expo,
  { path: '*', name: 'NotFound', component: NotFound, meta: { isLogin: true, level: '2,4,999' } },
];
console.log('routes',routes);
const router = new VueRouter({
  mode: 'history',
  routes
});

// 前置
router.beforeEach((to, from, next) => {
  console.log('to: ', to);
  const vux = Vue.prototype.$store;
  const langList = vux.state.base.langList;
  const l = vux.state.base.LanguageCode;
  console.log('l: ', l);
  const l2 = to?.params?.lang?.toLowerCase();
  console.log('l2: ', l2);
  // console.log('to?.params: ', to);
  const lang =  l || l2;
  // console.log('lang::: ', lang);
  const query = to?.query
  // console.log('query: ', query);
  let urlQuery = ''
  function objectToString(obj) {
    let str = "";
    for(let key in obj) {
        str += `${key}=${obj[key]}&`;
    }
    console.log('str: ', str);

    return "?" + str.slice(0, -1); // 去掉最后一个逗号和空格
  }
  urlQuery = objectToString(query)
  console.log('urlQuery',urlQuery); // 输出： "{key1: value1, key2: value2}"
  if(to.path == '/speed/sureroute-test-object.html'){
    next(); // 继续导航
  }else{
    if (langList?.includes(l2)) { // 有语言
      next(); // 继续导航
    } else { // 没有语言的情况下补语言
      if(l2 == 'picturelive') next(`/${lang}/picturelive/${urlQuery}`);
      else if (to.path !== `/${lang}`) { // 避免无限递归调用
        if(query) next(`/${lang}${urlQuery}`);
      } else {
        next(); // 继续导航
      }
    }
  }
  
});

router.afterEach(() => {
});

export default router;
